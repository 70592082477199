import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import SEO from "../components/seo"
import LayoutBlog from "../components/layout-blog"
import { PageTitle, PostText } from "../utils/common-styles"
import colors from "../utils/colors"
import AuthorBio from '../components/authorBio'
const Container = styled.div`
  padding: 10px 0;
  max-width: 600px;
  margin: 0px auto; 
  font-size: 18px;
`

const PostImage = styled.img`
  display: block;
  border-raduis: 10px;
  margin-bottom: 40px;
`

const Tags = styled.ul`
  list-style: none;
  display: flex;
  margin: 0 0 30px 0;
`

const Tag = styled.li`
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 18px;
  color: ${colors.GREEN};
  margin: 0 20px 0 0;
`



const PostTemplate = ({
  data: {
    contentfulPost: {
      title,
      image: {
        title: imageTitle,
        fluid: {
          src,
        },
      },
      abstract,
      text: {
        childMarkdownRemark: {
          html: textHtml,
        },
      },
      tags,
    }
  }
}) => {
  const abstractHtml = abstract ? (
    <PostText dangerouslySetInnerHTML={{ __html: abstract.childMarkdownRemark.html }} />
  ) : null

  return (
    <LayoutBlog title={title}>
      <SEO title={`${title} | Blog`} />
      <Container>
        <PageTitle>{title}</PageTitle>
        {/* <PostImage src={src} alt={imageTitle} /> */}
        { abstractHtml }
        <PostText dangerouslySetInnerHTML={{ __html: textHtml }} />
        {/* { tagsList } */}
        <AuthorBio/>
      </Container>
    </LayoutBlog>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulPost(slug: {eq: $slug}) {
      id
      slug
      title
      date
      image {
        title
        fluid(maxWidth: 960) {
          src
        }
      }
      abstract {
        childMarkdownRemark {
          html
        }
      }
      text {
        childMarkdownRemark {
          html
        }
      }
      tags {
        tag
        label
      }
    }
  }
`
