import React from "react"
import styled from "styled-components"
import colors from "../utils/colors"


const BioCard = styled.div`
  padding-top: 40px;
  border-top: 1px solid ${colors.BLUE};
  font-size: 18px;
  line-height: 29px;
`

const AuthorBio = props => (
  <>
    <BioCard>
      <h4>Om Softwareentwicklerfinden.de</h4>
      <p>
        Ich habe die Website erstellt, weil ich einen Bedarf an Informationen zu der Frage gesehen
        habe, wo man talentierte Entwickler findet. Ich habe bemerkt, dass es nicht viele
        Informationen gibt, die einfach erklären, wie und wo sich gute Entwickler finden lassen.
        Natürlich sind dies keine vollständigen Informationen darüber, wie man Entwickler findet,
        sondern es ist mein Versuch, übersichtliche Informationen auf interaktive Weise
        bereitzustellen. Ich habe sowohl eine Reihe von Artikeln darüber, was bei der Suche nach
        Entwicklern zu bedenken ist (Sie finden sie im Blog-Bereich), als auch den Hauptschwerpunkt
        der Seite: ein interaktives Quiz, in dem Sie eine Reihe von Fragen beantworten können.
      </p>
      <strong>Victor Björklund</strong>

    </BioCard>

  </>
)

export default AuthorBio
